import React from "react"
import { Container } from "react-bootstrap"
import Aboutpage from "components/pages/About"
import Formpage from "components/pages/Form"
import HeaderTwo from "components/shared/HeaderTwo"
import Footer from "components/shared/Footer"
import CompanyJourney from "components/pages/CompanyStatus"
import { Helmet } from "react-helmet"

const Companypage = () => {
	return (
		<>
			<Helmet>
				<title>Refstal Solutions | Company </title>
			</Helmet>
			<HeaderTwo />
			<Aboutpage />
			<CompanyJourney />
			<Container>
				<Formpage />
			</Container>
			<Footer />
		</>
	)
}

export default Companypage
